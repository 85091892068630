<script>
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  import { STATUS_OUT_FOR_DELIVERY } from "~/libs/constants";
  import { formatTrackingNumber } from "~/libs/utils";
  import PackageDropPlace from "~/pages/Search/Update/EditableFields/PackageDropPlace.svelte";
  import RedeliveryDateTime from "~/pages/Search/Update/EditableFields/RedeliveryDateTime.svelte";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  const dispatch = createEventDispatcher();

  /** @type {object} 登録ボタンを有効化してよいか判断するための一覧 */
  let registerButtonState = {
    redeliveryContext: null,
    packageDropPlace: null,
  };

  /** @type {object} 更新する荷物情報を保持する */
  let keptUpdateShipmentEvent = {};

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   * @param {CustomEvent} event
   */
  function onInputChange(event) {
    registerButtonState[event.detail.updateFieldName] =
      event.detail.isAllConditionsMet;
    const otherFields = { ...registerButtonState };
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    const isAllConditionsMet = Object.values(otherFields).some(
      (value) => value === true,
    );

    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    if (keptUpdateShipmentEvent) {
      // 保持している更新情報がある場合は、それを引き継ぐ
      Object.assign(updateShipmentEvent, keptUpdateShipmentEvent);
    }

    if (event.detail.updateFieldName === "redeliveryContext") {
      // 再配達希望日時が変更された場合

      updateShipmentEvent.redeliveryContext =
        event.detail.updateShipmentEvent.redeliveryContext;

      if (shipment.status === STATUS_OUT_FOR_DELIVERY && shipment.driverId) {
        // 持出中の場合はドライバーに通知する
        const message = {
          title: $_(
            "pages.Search.pushNotificationMessage.title.adjustedRedeliveryDatetime",
          ),
          body: $_("pages.Search.pushNotificationMessage.body", {
            values: {
              trackingNumber: formatTrackingNumber(shipment.trackingNumber),
            },
          }),
        };
        updateShipmentEvent.pushNotification = {
          title: message.title,
          body: message.body,
          data: {
            message: message,
            trackingNumber: shipment.trackingNumber,
            adjustedRedeliveryDatetime:
              event.detail.updateShipmentEvent.redeliveryContext
                .adjustedRedeliveryDatetime,
          },
        };
      }
    } else if (event.detail.updateFieldName === "packageDropPlace") {
      // 受け渡し方法が変更された場合
      updateShipmentEvent.packageDropPlace =
        event.detail.updateShipmentEvent.packageDropPlace;

      if (!updateShipmentEvent.pushNotification) {
        // pushNotificationが未設定の場合は通知メッセージも含め設定
        const message = {
          title: $_(
            "pages.Search.pushNotificationMessage.title.packageDropPlace",
          ),
          body: $_("pages.Search.pushNotificationMessage.body", {
            values: { trackingNumber: shipment.trackingNumber },
          }),
        };
        updateShipmentEvent.pushNotification = {
          title: message.title,
          body: message.body,
          data: {
            message: message,
            trackingNumber: shipment.trackingNumber,
            packageDropPlace: event.detail.updateShipmentEvent.packageDropPlace,
          },
        };
      } else {
        // pushNotificationが設定済(再配達希望日時も同時に変更あり)の場合は更新データのみ設定
        updateShipmentEvent.pushNotification.data.packageDropPlace =
          event.detail.updateShipmentEvent.packageDropPlace;
      }
    }
    keptUpdateShipmentEvent = updateShipmentEvent;

    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet,
      updateShipmentEvent,
    });
  }
</script>

<div class="wrapper">
  <RedeliveryDateTime {shipment} on:inputChange={onInputChange} />
  <PackageDropPlace {shipment} on:inputChange={onInputChange} />
</div>
