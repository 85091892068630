<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { createEventDispatcher, getContext } from "svelte";

  import NumberRangeFilter from "~/components/NumberRangeFilter.svelte";
  import SelectFilter from "~/components/SelectFilter.svelte";
  import TextFilter from "~/components/TextFilter.svelte";
  import { CONTEXT_KEY_USER, driverConditionType } from "~/libs/constants";
  import { sortData, toggleSortIcon } from "~/libs/tableUtils";
  import {
    getCenterNameAndPackageNumString,
    getCenterNameString,
    getDaysFromLastWorkedAt,
    getWorkedAtString,
  } from "~/pages/Activity/activityUtils";
  import LastWorkedStatus from "~/pages/Activity/LastWorkedStatus.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {Array<import("~/libs/commonTypes").DriverActivity>} */
  export let results = [];

  /** 現在選択中のドライバー番号 */
  export let currentDriverIndex;

  /** @type {boolean} 確認状態を保存するボタンの活性非活性 */
  export let disabledUpdateButton = true;

  /** @type {number} 幹線輸送ドライバー・宅配ドライバーどちらのページを表示するか */
  export let driverType;

  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  export let centersMap;

  (() => {
    results.forEach((result) => {
      const workTimes = result.workTimes;
      if (!workTimes) {
        return;
      }

      const lastTimeset = workTimes[workTimes.length - 1];
      if (lastTimeset.endAt) {
        // 業務終了日時が存在する場合は業務終了済みフラグを立てる
        result.isFinished = true;
      }
    });
  })();

  const dispatch = createEventDispatcher();

  const columns = [
    { header: "No.", id: "driverIndex", accessor: (item) => item.index ?? "" },
    {
      header: "表示名",
      id: "userDisplayName",
      accessor: (item) => item.userDisplayName ?? "",
    },
    {
      header: "最終勤務",
      id: "lastWorkedStatus",
      accessor: (item) => getDaysFromLastWorkedAt(item.workTimes),
    },
    {
      header: "",
      id: "lastWorkedAt",
      accessor: (item) => getWorkedAtString(item.workTimes),
    },
    {
      header: "管理者確認",
      id: "managerConfirmation",
      accessor: (item) => item.checked,
    },
    {
      header: "所属会社",
      id: "companyName",
      accessor: (item) => item.companyName ?? "",
    },
  ];
  if (driverType === driverConditionType.COURIER) {
    columns.push(
      {
        header: "担当配送センター",
        id: "locationId",
        accessor: (item) =>
          getCenterNameString(centersMap, item.results?.locationSourceIdList),
      },
      {
        header: "未配達",
        id: "undelivered",
        accessor: (item) => item.deliveryRecordsCount.undelivered,
      },
      {
        header: "配達済",
        id: "delivered",
        accessor: (item) => item.deliveryRecordsCount.delivered,
      },
      {
        header: "配達不可",
        id: "undeliverable",
        accessor: (item) => item.deliveryRecordsCount.undeliverable,
      },
    );
  } else if (driverType === driverConditionType.TRUCKER) {
    columns.push(
      {
        header: "荷受けした拠点",
        id: "transportSourceId",
        accessor: (item) =>
          getCenterNameString(centersMap, item.transportSourceIdList),
      },
      {
        header: "配送先センター",
        id: "transportDestinationId",
        accessor: (item) =>
          getCenterNameAndPackageNumString(
            centersMap,
            item.transportDestivationIdAndPackageNumList,
          ),
      },
    );
  }

  $: resultsNum = sortedData.length;
  /** @type {object | null} */
  let sortedColumn = null;
  /** @type {"asc" | "desc"} 検索結果の並び順が昇順か降順か */
  let sortOrder = "asc";

  /** @type {Array<import("~/libs/commonTypes").DriverActivity>} フィルタリングされた検索結果 */
  $: filteredData = results.filter((result) => {
    /** @type {number} 初回は最終勤務日から経過した日数を表示する */
    const daysSinceLastWorked = getDaysFromLastWorkedAt(result.workTimes);
    return 0 <= daysSinceLastWorked && daysSinceLastWorked <= 1;
  });
  /** @type {Array<import("~/libs/commonTypes").DriverActivity>} フィルタリング→ソートされた検索結果 */
  $: sortedData = sortedColumn
    ? sortData(filteredData, sortedColumn, sortOrder)
    : filteredData;

  /** フィルタリングする前の検索結果 */
  let originalResults = new Set(results);
  /** 表示名でフィルタリングした結果 */
  let filteredUserDisplayName;
  /** 最終勤務でフィルタリングした結果 */
  let filteredLastWorkedStatus;
  /** 所属会社でフィルタリングした結果 */
  let filteredCompanyName;
  /** 担当配送センターでフィルタリングした結果 */
  let filteredLocationId;
  /** 荷受けした拠点でフィルタリングした結果 */
  let filteredTransportSourceId;

  /**
   * 選択肢もしくはテキストによるフィルタリング機能を適用する
   * @param {CustomEvent} event
   */
  function applyFilter(event) {
    if (event.detail.columnId === "userDisplayName") {
      filteredUserDisplayName = new Set(event.detail.values);
    } else if (event.detail.columnId === "lastWorkedStatus") {
      filteredLastWorkedStatus = new Set(event.detail.values);
    } else if (event.detail.columnId === "companyName") {
      filteredCompanyName = new Set(event.detail.values);
    } else if (event.detail.columnId === "locationId") {
      filteredLocationId = new Set(event.detail.values);
    } else if (event.detail.columnId === "transportSourceId") {
      filteredTransportSourceId = new Set(event.detail.values);
    }

    filteredData = [...originalResults].filter((item) => {
      let judge = [];
      if (filteredUserDisplayName !== undefined) {
        judge.push(filteredUserDisplayName.has(item));
      }
      if (filteredLastWorkedStatus !== undefined) {
        judge.push(filteredLastWorkedStatus.has(item));
      }
      if (filteredCompanyName !== undefined) {
        judge.push(filteredCompanyName.has(item));
      }
      if (filteredLocationId !== undefined) {
        judge.push(filteredLocationId.has(item));
      }
      if (filteredTransportSourceId !== undefined) {
        judge.push(filteredTransportSourceId.has(item));
      }
      return judge.every((j) => j);
    });
  }
</script>

<div class="activityTable">
  <div class="resultHeader">
    <h1 class="resultNumber">全 {resultsNum.toLocaleString()}件</h1>
    <div class="confirmArea">
      <Button
        variant="unelevated"
        disabled={disabledUpdateButton}
        on:click={() => {
          dispatch("updateDriverActivityCheck");
        }}>確認状態を保存</Button
      >
    </div>
  </div>
  <div class="mdc-data-table">
    <div class="mdc-data-table__table-container">
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            {#each columns as column}
              <th
                class="mdc-data-table__header-cell"
                on:click={() => {
                  [sortOrder, sortedColumn] = toggleSortIcon(
                    column,
                    sortedColumn,
                    sortOrder,
                  );
                }}
              >
                {#if column.id !== "lastWorkedAt"}
                  <div class="th-item">
                    {column.header}
                    {#if sortedColumn?.id === column.id}
                      <span class="material-icons"
                        >{sortOrder === "asc"
                          ? "arrow_upward"
                          : "arrow_downward"}</span
                      >
                    {/if}
                  </div>
                  {#if column.id === "companyName"}
                    <!-- 選択肢によるフィルタリング機能 -->
                    <div class="filter-area">
                      <SelectFilter
                        {results}
                        columnId={column.id}
                        on:filter={applyFilter}
                      />
                    </div>
                  {/if}
                  {#if column.id === "userDisplayName" || column.id === "locationId" || column.id === "transportSourceId"}
                    <!-- テキストによるフィルタリング機能 -->
                    <div class="filter-area">
                      <TextFilter
                        {results}
                        columnId={column.id}
                        on:filter={applyFilter}
                      />
                    </div>
                  {/if}
                  {#if column.id === "lastWorkedStatus"}
                    <!-- 日付の範囲によるフィルタリング機能 -->
                    <div class="filter-area">
                      <NumberRangeFilter
                        {results}
                        columnId={column.id}
                        on:filter={applyFilter}
                      />
                    </div>
                  {/if}
                {/if}
              </th>
            {/each}
          </tr>
        </thead>
        <tbody class="mdc-data-table__content">
          {#if sortedData.length === 0}
            <tr class="mdc-data-table__row">
              <td class="mdc-data-table__cell" colspan="9">
                該当するデータがありません。
              </td>
            </tr>
          {:else}
            {#each sortedData as row}
              <tr
                class="mdc-data-table__row"
                class:selectedRow={currentDriverIndex === row.index}
                on:click={() => {
                  currentDriverIndex = row.index;
                }}
              >
                {#each columns as column}
                  <td
                    class="mdc-data-table__cell"
                    class:right={column.id === "undelivered" ||
                      column.id === "delivered" ||
                      column.id === "undeliverable"}
                    class:center={column.id === "managerConfirmation"}
                  >
                    {#if column.id === "managerConfirmation"}
                      {#if row.companyId !== userContext.loginUser.companyId}
                        <span class="disabledConfirmation">－</span>
                      {:else}
                        <FormField>
                          <Checkbox
                            on:click$stopPropagation
                            on:change={() => {
                              disabledUpdateButton = false;
                            }}
                            bind:checked={row.checked}
                          />
                        </FormField>
                      {/if}
                    {:else if column.id === "locationId" || column.id === "transportSourceId" || column.id === "transportDestinationId"}
                      <!-- 内部で生成した文言しか埋め込まれないためエスケープ不要 -->
                      {@html column.accessor(row)}
                    {:else if column.id === "lastWorkedStatus"}
                      <LastWorkedStatus
                        numberOfDays={Number(column.accessor(row))}
                        isFinished={row.isFinished}
                      />
                    {:else if column.id === "lastWorkedAt"}
                      <div class="lastWorkedTimes">
                        <!-- 内部で生成した文言しか埋め込まれないためエスケープ不要 -->
                        {@html column.accessor(row)}
                      </div>
                    {:else if column.id === "undelivered" || column.id === "delivered" || column.id === "undeliverable"}
                      {`${column.accessor(row).toLocaleString()}個`}
                    {:else}
                      {column.accessor(row)}
                    {/if}
                  </td>
                {/each}
              </tr>
            {/each}
          {/if}
        </tbody>
      </table>
    </div>
  </div>
</div>

<style lang="scss">
  .activityTable {
    :global(.mdc-data-table__table-container) {
      max-height: calc(100vh - 295px);
      min-height: calc(100vh - 295px);
      overflow: auto;
    }
    :global(.mdc-data-table__cell) {
      overflow: unset;
      padding: 0 16px;
    }
    :global(.mdc-tooltip-wrapper--rich .tooltip-eventer) {
      box-sizing: border-box;
      padding: 15.5px 6px;
      height: 51px;
    }
    :global(
      .mdc-data-table__row
        .mdc-data-table__cell:first-of-type
        .mdc-tooltip-wrapper--rich
        .tooltip-eventer
    ) {
      padding: 10.5px 16px;
    }
    :global(.mdc-data-table__header-cell) {
      padding: 0 16px;
    }
    .resultHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }
    .resultNumber {
      margin: 12px 0 5px 20px;
    }
    .confirmArea {
      margin: 4px 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 4px;
    }
    :global(.mdc-data-table) {
      width: 100%;
      max-width: 100%;
      border-collapse: collapse;
      max-height: calc(100vh - 236px);
      overflow-x: auto;
      overflow-y: none;
    }
    :global(.mdc-data-table__table thead) {
      position: sticky;
      top: 0;
      z-index: var(--z-index-sticky-area);
    }
    .mdc-data-table__row {
      cursor: pointer;

      &.selectedRow {
        background-color: aliceblue;
        font-weight: 900 !important;
      }

      .right {
        text-align: right;
      }

      .center {
        text-align: center;
      }

      .disabledConfirmation {
        margin-right: 4px;
      }
    }
    th {
      background-color: #eaf5ff;
      vertical-align: middle;
      font-size: small;

      .th-item {
        display: flex;
        position: relative;

        span {
          position: relative;
          margin-left: 3px;
          top: 3px;
          font-size: 18px;
          color: #5c5c5c;
        }
      }
    }
    td {
      vertical-align: middle;
      font-size: small;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .lastWorkedTimes {
      margin: 6px 0;
    }
  }

  @media screen and (max-width: 768px) {
    .activityTable {
      :global(.mdc-data-table) {
        height: fit-content;
        max-height: 436px;
        min-height: auto;
      }
      :global(.mdc-data-table__table-container) {
        max-height: 436px;
        min-height: auto;
      }
    }
  }
</style>
