<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import { HTTPError } from "ky";
  import { createEventDispatcher, getContext, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_USER,
    OFA_GROUP_ID,
    ROLES,
    USER_KINDS_FOR_CONTRACT,
    USER_KINDS_FOR_PARTNER,
  } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { generatePassword } from "~/libs/passwordGenerator";
  import { managementAddUserClose, needReload } from "~/libs/stores";
  import {
    getCompanyNameOptionGroups,
    getUserKindsInfo,
  } from "~/pages/Management/managementUtils";

  /** @type {import("~/libs/backendApi").GetCompaniesResponse}>} */
  export let companyNameList;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let open;
  /** 選択された会社名 @type {number} */
  let selectedCompanyId;
  /** 選択された会社名(選択処理後) @type {number} */
  let processedSelectedCompanyId;
  /** 選択中の会社情報 @type {import("~/libs/backendApi").Company} */
  let selectedCompany;
  /** 既定の会社名(EC事業者・宅配パートナーの場合は選択肢がないためこちらを参照する) @type {string} */
  let regulationCompanyName;
  let userKindsList = [];
  let userIdValue;
  let userKindsValue;
  let displayNameValue;
  let contactInfoValue;
  let emailAddress;
  let password;
  let userKindsDisabled = true;
  let saveButtonDisabled = true;
  let addedLoginData = { userName: "", password: "" };
  let dispatch = createEventDispatcher();
  let regulationUserKind;

  /** 会社名の選択肢 */
  let companyNameOptionGroups = [];

  /** @type {boolean} 宅配ドライバーロールのユーザーが幹線輸送を行うことを可能とするか */
  let allowCoreDelivery = false;

  /** 切り替え可能なECサイト @type {Array<{id: number, name: string, selected?: boolean}>} */
  let options = [];

  $: if (
    processedSelectedCompanyId !== undefined &&
    processedSelectedCompanyId !== null &&
    userIdValue &&
    userKindsValue &&
    displayNameValue &&
    password
  ) {
    saveButtonDisabled = false;
  }

  $: if (
    !(
      processedSelectedCompanyId !== undefined &&
      processedSelectedCompanyId !== null
    ) ||
    !userIdValue ||
    !userKindsValue ||
    !displayNameValue ||
    !password
  ) {
    saveButtonDisabled = true;
  }

  // 選択された会社名が変更された場合、切替可能ECサイトを設定する
  $: if (processedSelectedCompanyId !== selectedCompanyId) {
    for (let i = 0; i < companyNameList.length; i++) {
      if (companyNameList[i].id === selectedCompanyId) {
        // 選択されたECサイト
        selectedCompany = companyNameList[i];
        if (selectedCompany.switchableCompanies === undefined) {
          // 切替可能ECサイトがない場合
          console.log("切替可能ECサイトがありません");
          options = [];
          break;
        }
        for (let j = 0; j < selectedCompany.switchableCompanies?.length; j++) {
          // 選択されたECサイトの切替可能ECサイト
          const switchableCompany = selectedCompany.switchableCompanies[j];
          if (switchableCompany.id === selectedCompanyId) {
            // 選択されたECサイト＝切替可能ECサイトの場合
            options.push({
              id: switchableCompany.id,
              name: switchableCompany.name,
              selected: true,
            });
          } else {
            // 選択されたECサイト＝切替可能ECサイトの場合
            options.push({
              id: switchableCompany.id,
              name: switchableCompany.name,
              selected: false,
            });
          }
        }
        break;
      }
    }
    console.log("切替可能ECサイト", options);
    options = options.sort((a, b) => a.id - b.id);
    processedSelectedCompanyId = selectedCompanyId;
  }

  onMount(openDialog);

  function openDialog() {
    try {
      if (
        userContext.hasContractAdminRole() ||
        userContext.hasSccOperatorRole() ||
        userContext.hasTrumpAdminRole() // FIXME: 暫定でトランプ管理者が他事業者のユーザーを管理できるようにする
      ) {
        userKindsList = USER_KINDS_FOR_CONTRACT;
        companyNameOptionGroups = getCompanyNameOptionGroups(companyNameList);
        if (companyNameList.length == 1) {
          selectedCompanyId = companyNameList[0].id;
          userKindsDisabled = false;
        }
      } else if (userContext.hasShippingPartnerAdminRole()) {
        selectedCompanyId = companyNameList[0].id;
        regulationCompanyName = companyNameList[0].name;
        userKindsList = USER_KINDS_FOR_PARTNER;
        userKindsDisabled = false;
      } else if (userContext.hasEcAdminRole()) {
        selectedCompanyId = companyNameList[0].id;
        regulationCompanyName = companyNameList[0].name;
        userKindsValue = "ec-admin";
        regulationUserKind = "EC事業者／管理者";
      } else {
        throw new Error();
      }
    } catch (e) {
      console.error(e);
      managementAddUserClose.set(true);
    }
    open = true;
  }

  function closeHandler(event) {
    if (event.detail.action == "cancel") {
      managementAddUserClose.set(true);
    }
  }

  const execUsersApi = async () => {
    /** @type {import("~/libs/backendApi").AddUserRequest} */
    const body = {
      userName:
        String(processedSelectedCompanyId).padStart(4, "0") + "/" + userIdValue,
      displayName: displayNameValue,
      role: userKindsValue,
      initialPassword: password,
      emailAddress: emailAddress,
      contactInfo: contactInfoValue,
    };

    if (userKindsValue === "ec-admin") {
      // EC管理者の場合、切替可能なECサイトを設定
      body.switchableCompanyIds = options
        .filter((option) => option.selected)
        .map((option) => option.id);
    }

    if (userKindsValue === "shipping-partner-driver") {
      // 宅配ドライバーの場合、幹線輸送可否を設定
      if (allowCoreDelivery) {
        body.switchableRoles = [ROLES.CONTRACT_DRIVER];
      }
    }

    addedLoginData = {
      userName: body.userName,
      password: body.initialPassword,
    };
    dispatch("postData", addedLoginData);

    await backendApi.addUser(body);
  };

  const addUser = loadingProgress.wrapAsync(async () => {
    try {
      await execUsersApi();
      dispatch("message", {
        result: "success",
        title: $_("message.updateComplete"),
        message: "",
      });
    } catch (error) {
      /** @type {import("~/libs/backendApi").ErrorResponse} */
      const errorResponse = error["errorResponse"];

      if (
        errorResponse?.title == "invalidParam" &&
        errorResponse?.details?.violations
      ) {
        /** @type {Array<{level: string, path: string, message: string}>} */
        const violations = errorResponse.details.violations;
        const outputViolations = violations
          .map((v) => "・" + $_("classes.user." + v.path) + "：" + v.message)
          .join("<br />");

        showErrorMessage(
          new HandledError(
            $_("errors.userCreateError.title"),
            $_("errors.userCreateError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else if (
        errorResponse?.title == "userNameAlreadyExist" &&
        errorResponse?.details?.violations
      ) {
        /** @type {{path: string, param: string}} */
        const violations = errorResponse.details.violations;
        const outputViolations =
          $_("classes.user." + violations.path) + "：" + violations.param;
        showErrorMessage(
          new HandledError(
            $_("errors.userCreateExistUserNameError.title"),
            $_("errors.userCreateExistUserNameError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else if (
        errorResponse?.title == "emailAddressAlreadyExist" &&
        errorResponse?.details?.violations
      ) {
        /** @type {{path: string, param: string}} */
        const violations = errorResponse.details.violations;
        const outputViolations =
          $_("classes.user." + violations.path) + "：" + violations.param;
        showErrorMessage(
          new HandledError(
            $_("errors.userCreateExistEmailAddressError.title"),
            $_("errors.userCreateExistEmailAddressError.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else {
        showErrorMessage(error);
      }
    }
    managementAddUserClose.set(true);
    needReload.set(true);
  });

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      dispatch("message", {
        result: "failed",
        title: error.title,
        message: error.message,
      });
    } else {
      if (error instanceof HTTPError && error.response?.status == 401) {
        dispatch("message", {
          result: "failed",
          title: $_("errors.unauthorized.title"),
          message: $_("errors.unauthorized.message"),
        });
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        dispatch("message", {
          result: "failed",
          title: $_("errors.forbidden.title"),
          message: $_("errors.forbidden.message"),
        });
      } else {
        console.error(error);
        dispatch("message", {
          result: "failed",
          title: $_("errors.defaultMessage.title"),
          message: $_("errors.defaultMessage.message"),
        });
      }
    }
  }

  function handleUserKindsList(event) {
    [userKindsValue, userKindsList, userKindsDisabled, regulationUserKind] =
      getUserKindsInfo(Number(event.target.value), companyNameList);
  }
</script>

<Dialog
  bind:open
  aria-labelledby="detail-dialog-title"
  aria-describedby="detail-dialog-content"
  on:SMUIDialog:closed={closeHandler}
  style="margin-top: 30px; max-height: 95%;"
>
  <Title id="detail-dialog-title">ユーザーを追加する</Title>

  <Content id="detail-dialog-content">
    <div class="item">
      <div class="itemName">会社名</div>
      <div class="itemLine">
        {#if userContext.hasContractAdminRole() || userContext.hasSccOperatorRole() || userContext.hasTrumpAdminRole()}<!-- FIXME: 暫定でトランプ管理者が他事業者のユーザーを管理できるようにする -->
          <select
            name="companyName"
            id="companyName"
            class="selectArea"
            bind:value={selectedCompanyId}
            on:change={handleUserKindsList}
          >
            <option value="" selected disabled>選択してください</option>
            {#each companyNameOptionGroups as [groupName, companyNames]}
              {#if companyNames.length > 0}
                <optgroup label={groupName}>
                  {#each companyNames as { id, name }}
                    <option value={id}>{name}</option>
                  {/each}
                </optgroup>
              {/if}
            {/each}
          </select>
        {:else}
          <input
            type="text"
            class="inputArea"
            bind:value={regulationCompanyName}
            disabled
          />
        {/if}
      </div>
    </div>
    <div class="item">
      <div class="itemName">ユーザー種別</div>
      <div class="userKindsWrapper">
        <div class="itemLine">
          {#if userKindsDisabled}
            <input
              type="text"
              class="inputArea"
              bind:value={regulationUserKind}
              disabled
            />
          {:else}
            <select
              name="userKindsChange"
              id="userKindsChange"
              class="selectArea"
              bind:value={userKindsValue}
            >
              <option value="" selected disabled>選択してください</option>
              {#each userKindsList as { value, kind }}
                <option {value}>{kind}</option>
              {/each}
            </select>
          {/if}
        </div>
        {#if selectedCompanyId !== OFA_GROUP_ID}
          {#if userKindsValue === ROLES.SHIPPING_PARTNER_DRIVER}
            <FormField>
              <Checkbox bind:checked={allowCoreDelivery} />
              <span slot="label">幹線輸送を行うことを可能とする</span>
            </FormField>
          {:else if userKindsValue === ROLES.SHIPPING_PARTNER_ADMIN}
            <FormField>
              <Checkbox checked disabled />
              <span slot="label" style="color:#666"
                >幹線輸送を行うことを可能とする</span
              >
            </FormField>
          {/if}
        {/if}
      </div>
    </div>
    {#if userKindsValue === "ec-admin" && options.length > 0}
      <div class="item">
        <div class="itemName">切替可能な<br />ECサイト</div>
        <div class="itemBlock">
          {#each options as option}
            {#if option.id !== selectedCompany.id}
              <FormField style="margin-right: 10px;">
                <Checkbox bind:checked={option.selected} />
                <span slot="label">{option.name}</span>
              </FormField>
            {/if}
          {/each}
        </div>
      </div>
    {/if}
    <div class="item">
      <div class="itemName">ユーザーID</div>
      <div class="itemFlex">
        <div id="inputNumber">
          <span>{String(processedSelectedCompanyId).padStart(4, "0")}</span>
        </div>
        <input type="text" id="inputOther" bind:value={userIdValue} />
      </div>
    </div>
    <div class="item">
      <div class="itemName">表示名</div>
      <div class="itemLine">
        <input type="text" class="inputArea" bind:value={displayNameValue} />
      </div>
    </div>
    <div class="item">
      <div class="itemName">
        <p>メールアドレス<span class="optionLabel">(任意)</span></p>
      </div>
      <div class="itemLine">
        <input type="text" class="inputArea" bind:value={emailAddress} />
      </div>
    </div>
    <div class="item">
      <div class="itemName">パスワード</div>
      <div class="itemLine">
        <input type="text" class="inputArea" bind:value={password} />
        <Button
          variant="unelevated"
          on:click={() => {
            password = generatePassword();
          }}
          style="margin-top: 4px;">初期パスワードを生成する</Button
        >
      </div>
    </div>
    <div class="item">
      <div class="itemName">
        <p>管理者専用メモ<span class="optionLabel">(任意)</span></p>
      </div>
      <div class="itemLine">
        <textarea
          name="inputContactInfo"
          id="inputContactInfo"
          bind:value={contactInfoValue}
        ></textarea>
        <p class="subText">所属や連絡先などの情報保持にご利用いただけます。</p>
      </div>
    </div>
    <div class="alertMessage">
      <span class="material-icons .md-18"> warning_amber </span>
      <p>
        設定したパスワードはお手元に必ずお控えの上、<br />
        当該ユーザーへ正しくお伝えください。
      </p>
    </div>
  </Content>
  <Actions>
    <Button style="background-color: #D9D9D9; color: #333;" action={"cancel"}
      >閉じる</Button
    >
    <Button
      variant="unelevated"
      on:click={addUser}
      bind:disabled={saveButtonDisabled}
      action={"update"}>保存</Button
    >
  </Actions>
</Dialog>

<style lang="scss">
  .item {
    display: flex;
    position: relative;
    width: 500px;
    margin: 6px auto;
    padding-bottom: 6px;
    border-bottom: 1px solid #eee;
  }
  .itemName {
    background-color: #b4d0f1cb;
    width: 150px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
    line-height: 1.2;
  }
  .userKindsWrapper {
    width: 344px;
  }
  .itemLine {
    width: 350px;
  }
  .itemFlex {
    width: 350px;
    display: flex;
    justify-content: start;
  }
  .itemBlock {
    width: 350px;
    display: block;
  }
  .selectArea {
    width: 342px;
    height: 36px;
    background-color: #fff;
  }
  .inputArea {
    width: 335px;
    height: 30px;
    background-color: #fff;
  }
  #inputNumber {
    width: 80px;
    font-size: 14px;
    background-color: #ddd;
    border: 1px solid #777;
    border-radius: 3px;
    text-align: center;
    padding-top: 5px;
  }
  #inputNumber span {
    color: #333;
  }
  #inputOther {
    width: 252px;
  }
  #inputContactInfo {
    display: block;
    width: 335px;
    height: 60px;
  }
  .optionLabel {
    font-size: 10px;
  }
  .subText {
    margin-top: 6px;
    padding-left: 1em;
    text-indent: -1em;
    line-height: 1.2em;
    font-size: 11px;
    color: #666;
  }
  .alertMessage {
    margin: 8px 0;
    font-size: 13px;
    line-height: 1.4;
    padding: 6px 0;
    color: #672b2a;
    background-color: #ffe7e7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .material-icons {
      color: #d74141;
    }
  }
</style>
